import React, { CSSProperties } from 'react';
import ExternalLink from './ExternalLink';

/**
 * Link to MailChimp subscription form
 * (with <ExternalLink/> since outside our React frontend)
 *
 * @param className
 * @param style
 * @param children
 * @constructor
 */
export const SubscribeLink: React.FC<{
    className?: string;
    style?: CSSProperties;
}> = ({
    className,
    style,
    children
}) => <ExternalLink
    className={className}
    href={'https://www.caymanchem.com/subscribe'}
    style={style}
>
    { children || 'Subscribe' }
</ExternalLink>;