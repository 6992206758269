import * as React from 'react';
import { Helmet } from 'react-helmet';

interface TitleProps {
    components: Array<string>;
}

export default class Title extends React.PureComponent<TitleProps> {
    render() {
        const title: string = this.props.components.concat('Cayman Chemical').join(' | ');

        return <Helmet><title>{title}</title></Helmet>;
    }
}