export const overview: string = 'overview';
export const none = 'none';
export const processing = 'Processing your request...';
export const success = 'Success! Your request has been received.';
export const other = 'other';
export const horizontalEllipsis = '\u2026';
export const multiplicationSign = '×';
export const threeBars = '\u2630';

export enum Messages {
    contactForPricing = 'Contact a distributor in your area for availability',
}

export const successTimeout = 7000;