export default function SystemUpgradeBanner() {

    // Phase 1
    return (
        <div id={'motd'} className={'text-center p-3 bg-black'}>
            <h5>
                <strong>
                    ⚠️{' '}IMPORTANT UPDATE FROM CAYMAN
                </strong>
            </h5>

            <p>
                Starting at 12 p.m. EST on November 22<sup>nd</sup>, 2024 Cayman will be implementing essential upgrades to our system that will cause a slight delay in order processing and shipping.<br/>
                We appreciate your understanding and will be adding a free Cayman Squish Fish to any domestic orders placed by November 21<sup>st</sup>, 2024. Please contact
                {' '}
                <a href={'/contact/cc'}>
                    Customer Service
                </a>
                {' '}
                with any questions or to place an order.
            </p>
        </div>
    );
}