import flatten from 'lodash-es/flatten';
import { RaptaId } from 'src/types/RaptaId';
import { Core } from '../../constants/core';
import { CatalogNumber } from '../../types/CatalogNumber';

const cdn: boolean = true;

export const cdnUrl  = (uri: string) => `https://cdn.caymanchem.com/cdn/${uri}`;
export const cdn2Url  = (uri: string) => `https://cdn2.caymanchem.com/cdn/${uri}`;
export const nemoUrl = (uri: string) => `${process.env.REACT_APP_NEMO_URL}${uri}`;
export const publicUrl = (uri: string) => `https://www.caymanchem.com${uri}`;
export const solrUrl = (core: Core) => `${process.env.REACT_APP_SOLR_URL}/${core}/select`;
export const staticUrl = (uri: string) => `https://static.caymanchem.com/${uri}`;
export const certificateOfAnalysisUrl = (file: string) => cdnUrl(`downloadCofa/${file}`);
export const plateLayoutUrl = (file: string) => cdnUrl(`platelayout/${file}`);
export const cmsUrl = (uri: string) => cdn2Url(`cms/caymanchem/${uri}`);
export const cmsImageUrl = (uri: string) => cmsUrl(`cmsImages/${uri}`);
export const literatureCmsUrl = (uri: string) => cmsUrl(`LiteratureCMS/${uri}`);
export const literatureThumbnailUrl = (uri: string) => cmsUrl(`LiteratureThumbnails/${uri}`);
export const msdsUrl = (catalogNumber: CatalogNumber) => cdnUrl(`msds/${catalogNumber}m.pdf`);
export const insertUrl = (fileName: string) => cdnUrl(`insert/${fileName}`);
export const msdsUrlFileName = (fileName: string) => cdnUrl(`msds/${fileName}`);
export const cofaUrl = (catalogBatchNumber: string) => cdnUrl(`downloadCofa/${catalogBatchNumber}-CofA.pdf`);
export const isocofaUrl = (fileName: string) => cdnUrl(`iso/${fileName}`);
/** 2020 landing page managed content */
export const landingUrl = (uri: string, landing: string | undefined = undefined) => cmsImageUrl(`landing/${typeof landing === 'string' ? `${landing}/` : ''}${uri}`);

export const productImageUrl = (itemId: string = '', size: string = 'page' ) => {
    const sizeUri = size === 'page' ? '' : `/${size}`;
    const fileName = itemId.endsWith('.png') ? itemId : `${itemId}.png`;

    return cdn ?
        cdn2Url(`productImages${sizeUri}/${fileName}`) :
        publicUrl(`/images/catalog/${sizeUri}/${fileName}`)
    ;
};

export const cslUrl = (uri: string) => cdnUrl(`csl/${uri}`);

export const searchTo = (raptaIds: RaptaId[] | undefined, q: string | undefined, catalogNums: string[] | undefined = undefined) => {
    const fqsFromRaptas = typeof raptaIds === 'undefined' ? [] : raptaIds.map(raptaId => `fq=raptas:${raptaId}`);
    const fqFromCatalogNums = typeof catalogNums === 'undefined' ? [] : `fq=catalogNum:(${catalogNums.join(' OR ')})`;
    const fqs = fqsFromRaptas.concat(fqFromCatalogNums);

    const qq = typeof q === 'string' ? [`q=${q}`] : [];
    const queryString = flatten([fqs, qq]).sort().join('&');
    const to = `/search${queryString.length > 0 ? `?${queryString}` : ''}`;
    return to;
};