import * as MobXReactRouter from '@superwf/mobx-react-router';
import { autorun, computed, makeObservable, reaction } from 'mobx';
import * as queryString from 'query-string';
import { ParsedQuery } from 'query-string';
import { overview } from '../constants/messagesConstants';
import { forensicBaseUrl, forensicNewsBaseUrl, literatureBaseUrl, newsBaseUrl } from '../constants/navigationConstants';
import { NautilusAutorun } from '../types/NautilusAutorun';
import { NautilusReaction } from '../types/NautilusReaction';

const fullSearchPattern = new RegExp('^/search?', 'i'); //  Don't .compile() RegExp, happens automatically now
const checkoutSearchPattern = new RegExp('^/checkout?', 'i');
const registrationPattern = new RegExp('^/register?', 'i');
const loginPattern = new RegExp('^/login', 'i');
const productPattern = new RegExp('^/product/', 'i');
const forensicsPattern = new RegExp(`^${forensicBaseUrl}?`, 'i');
const requestablePattern = new RegExp(`^${literatureBaseUrl}?`, 'i');
const newsPattern = new RegExp(`^${newsBaseUrl}?`, 'i');
const forensicNewsPattern = new RegExp(`^${forensicNewsBaseUrl}?`, 'i');
export default class RouterStore extends MobXReactRouter.RouterStore {

    onNavigationSubscribers: Array<() => void> = [];

    onQueryParamsSubscribers: Map<string, () => void> = new Map();

    get loginPage(): boolean {
        return loginPattern.test(this.currentPath);
    }

    get productPage(): boolean {
        return productPattern.test(this.currentPath);
    }


    get newsMode(): boolean {
        return newsPattern.test(this.currentPath) || forensicNewsPattern.test(this.currentPath);
    }

    get fullSearchMode(): boolean {
        return fullSearchPattern.test(this.currentPath);
    }

    get requestableMode(): boolean {
        return requestablePattern.test(this.currentPath);
    }

    get checkout(): boolean {
        return checkoutSearchPattern.test(this.currentPath);
    }

    get selectedForensicMenuItem(): string {
        return this.forensics ? this.location.pathname.split(forensicBaseUrl)[1] : '';
    }

    get forensics(): boolean {
        return forensicsPattern.test(this.currentPath);
    }

    get registration(): boolean {
        return registrationPattern.test(this.currentPath);
    }

    get currentPath(): string {
        document.title = 'Cayman Chemical'; //  reset document title on every navigation, expecting new page to customize
        return this.location ? this.location.pathname : (window as Window).location.pathname;
    }

    /** Query params string such as a=b&c=d&e=f&... */
    get currentSearch(): string {
        return this.location ? this.location.search : (window as Window).location.search;
    }

    get queryParams(): ParsedQuery {
        return queryString.parse(this.currentSearch);
    }

    get currentPathComponents(): string[] {
        return this.currentPath.split('/');
    }

    get lastPathComponent(): string {
        return this.currentPathComponents.slice(-1)[0];
    }

    get secondToLastPathComponent(): string {
        return this.currentPathComponents.slice(-2)[0];
    }

    get isMicrositeOverview(): boolean {
        return this.tab === overview;
    }

    get section(): string {
        return this.currentPathComponents[1];
    }

    get tab(): string {
        return this.currentPathComponents[2];
    }

    registerOnNavigation(callback: () => void) {
        this.onNavigationSubscribers.push(callback);
    }

    registerOnQueryParams(path: string, callback: () => void) {
        this.onQueryParamsSubscribers.set(path, callback);
    }

    onQueryParams: NautilusReaction<object> = {
        expression: () => this.queryParams,
        effect: () => this.onQueryParamsSubscribers.has(this.currentPath) && this.onQueryParamsSubscribers.get(this.currentPath)!.call(this),
        opts: {
            delay: 0,
            fireImmediately: true,
            name: 'onQueryParams',
        }
    };

    onPathChange: NautilusReaction<string> = {
        expression: () => {
            return this.currentPath;
        },
        effect: (uri: string) => {
            if (uri.indexOf('search') === -1) {
                window.scrollTo(0, 0);
            }
            this.onNavigation();
        },
        opts: {
            delay: 0, /* milliseconds */
            fireImmediately: false,
            name: 'onPathChange'
        }
    };

    onNavigation = () => this.onNavigationSubscribers.forEach((f) => f());

    constructor() {
        super();

        makeObservable(this, {
            loginPage: computed,
            productPage: computed,
            newsMode: computed,
            fullSearchMode: computed,
            requestableMode: computed,
            checkout: computed,
            selectedForensicMenuItem: computed,
            forensics: computed,
            registration: computed,
            currentPath: computed,
            currentSearch: computed,
            queryParams: computed,
            currentPathComponents: computed,
            lastPathComponent: computed,
            secondToLastPathComponent: computed,
            isMicrositeOverview: computed,
            section: computed,
            tab: computed
        });

        [].forEach((a: NautilusAutorun) => autorun(a.view, a.opts));
        [
            this.onPathChange,
            this.onQueryParams,
        ].forEach((r: NautilusReaction<any>) => reaction<any, boolean>(r.expression, r.effect, r.opts));
    }

    // TODO: add reaction for scroll to top
}