import { observer } from 'mobx-react';
import * as React from 'react';
import { CSSProperties } from 'react';
import { gtag } from '../../constants/gtag';
import { routerStore } from '../../storeSingleton/routerStore';

interface ExternalLinkProps {
    id?: string;
    className?: string;
    href: string;
    onClick?: () => void;
    style?: CSSProperties;
    title?: string;
    event?: [string, object];
}

const ExternalLink = observer(class ExternalLink extends React.PureComponent<ExternalLinkProps> {
    render() {
        const event = this.props.event;

        return <a
            id={this.props.id}
            className={`text-underline ${this.props.className || ''}`}
            href={this.props.href}
            title={this.props.title}
            onClick={() => {
                if (event) {
                    gtag('event', ...event);
                }

                if (typeof this.props.onClick === 'function') {
                    this.props.onClick();
                }
                routerStore.onNavigation();
                return true;
            }}
            rel={'noreferrer'}
            style={this.props.style}
            target={'_blank'}
        >
            {this.props.children}
        </a>;
    }
});
export default ExternalLink;