import * as React from 'react';
import { CSSProperties } from 'react';

interface MailtoLinkProps {
    className?: string;
    email: string;
    subject?: string;
    style?: CSSProperties;
    title?: string;
}

export default class MailtoLink extends React.PureComponent<MailtoLinkProps> {
    render() {
        return <a
            className={`text-underline ${this.props.className}`}
            href={`mailto:${this.props.email}${this.props.subject ? `?subject=${this.props.subject}` : ''}`}
            style={this.props.style}
            title={this.props.title}
            rel={'noopener noreferrer'}
            target={'_blank'}
        >
            {this.props.children || this.props.email}
        </a>;
    }
}