export enum MenuItemDisposition {
    Active = 1,
    Inactive = 2,
    Omit = 3,
}

export interface MenuItemDispositions {
    authenticated: MenuItemDisposition;
    guest: MenuItemDisposition;
}

export interface MenuItem extends MenuItemDispositions {
    id: string;
    label: JSX.Element | string;
    uri: string;
    classNames?: string;

    activeClass?: string
    inactiveClass?: string
}

export enum ApiMenuItems {
    OVERVIEW = 'cgmp-overview',
    SERVICES = 'cgmp-services',
    COMMERCIAL = 'cgmp-manufacturing',
    CONTACT = 'cgmp-contact',
}

export enum ApiMenuItemLabels {
    OVERVIEW = 'Overview',
    PRODUCTS = 'API Products',
    COMMERCIAL = 'Commercial API Manufacturing',
    CONTACT = 'Contact Us',
    SERVICES = 'API Contract Services'
}

export enum CancerMenuItems {
    OVERVIEW = 'overview',
    REGULATION = 'regulation',
    METABOLISM = 'metabolism',
    TUMOR_MICROENVIRONMENT = 'tumor-microenvironment',
    RESOURCES = 'resources',
}

export enum CancerMenuItemLabels {
    OVERVIEW = 'Overview',
    REGULATION = 'Cancer Cell Signaling & Regulation',
    METABOLISM = 'Cancer Metabolism',
    TUMOR_MICROENVIRONMENT = 'Tumor Microenvironment',
    RESOURCES = 'Cancer Resources & Literature',
}

export enum ForensicMenuItems {
    OVERVIEW = 'overview',
    ABOUT = 'about',
    SEARCH = 'search',
    PUBLICATIONS = 'publications',
    SERVICES = 'services'
}

export enum ForensicMenuItemLabels {
    OVERVIEW = 'Forensics Overview',
    ABOUT = 'About Cayman Forensic',
    SEARCH = 'Product Search & Drug ID',
    PUBLICATIONS = 'Literature',
    SERVICES = 'Services'
}

export enum ForensicSubMenuItems {
    CDC = 'faskit',
    CONTACT = 'contact',
    QUALITY = 'quality',
    CATEGORIES = 'categories',
    SEARCH = 'productSearch',
    DRUGID = 'drugId',
    FLIPBOOK = 'flipbook',
    CSL = 'csl',
    PAPERS = 'papers',
    NEWS = 'news',
    LIBRARY = 'library',
    FAQ = 'faq'
}

export enum LipidsMenuItems {
    OVERVIEW = 'overview',
    BIOLOGY = 'biology',
    HEALTHANDDISEASE = 'healthanddisease',
    RESOURCES = 'resources',
    PHARMACEUTICALDEVELOPMENT = 'pharma',
}

export enum LipidsMenuItemLabels {
    OVERVIEW = 'Lipid Standards',
    BIOLOGY = 'Lipid Roles in Biology',
    HEALTHANDDISEASE = 'Lipids in Health & Disease',
    RESOURCES = 'Lipid Resources & Literature',
    PHARMACEUTICALDEVELOPMENT = 'Lipids for Pharmaceutical Development',
}

export const loggedInOnly: MenuItemDispositions = {
    authenticated: MenuItemDisposition.Active,
    guest: MenuItemDisposition.Inactive
};
export const loginAndRegister: MenuItemDispositions = {
    authenticated: MenuItemDisposition.Omit,
    guest: MenuItemDisposition.Active
};
export const unconditional: MenuItemDispositions = {
    authenticated: MenuItemDisposition.Active,
    guest: MenuItemDisposition.Active
};

export const cancerBaseUrl = '/cancer/';
export const forensicBaseUrl = '/forensics/';
export const lipidsBaseUrl = '/lipids/';
export const literatureBaseUrl = '/literature';
export const newsBaseUrl = '/news';
export const forensicNewsBaseUrl = `${forensicBaseUrl}${newsBaseUrl}`;

export enum ServicesMenuItems {
    BIOANALYSIS = 'bioanalysis',
    MEDICINAL = 'medicinalchemistry',
    ANALYTICAL = 'chemicalanalysis',
    SYNTHESIS = 'chemicalsynthesis',
}

export enum ServicesMenuItemLabels {
    BIOANALYSIS = 'Bioanalysis & Assay Development',
    MEDICINAL = 'Medicinal Chemistry & Structural Biology',
    ANALYTICAL = 'Analytical Chemistry',
    SYNTHESIS = 'Chemical Synthesis',
}