import { StrictMode } from 'react';
import { CookiesProvider } from 'react-cookie';
import { render } from 'react-dom';
//import { Metric, onCLS, onINP, onLCP, ReportCallback } from 'web-vitals';
import App from './App';

if (process.env.REACT_APP_ENVIRONMENT !== 'prod') {
    // https://web.dev/inp/
    /*new PerformanceObserver((entryList: any): void => {
        for (const entry of entryList.getEntries()) {
            if (entry.interactionId) {
                const duration: number = entry.processingEnd - entry.startTime;
                console.log('Interaction:', entry.name, duration, entry);
            }
        }
    }).observe({type: 'event', buffered: true, durationThreshold: 16});

    // https://www.npmjs.com/package/web-vitals
    const log: ReportCallback = (metric: Metric) => console.log('Core Web Vitals (CWV)', metric);

    onCLS(log);
    onLCP(log);
    onINP(log);*/
}

render(<StrictMode><CookiesProvider>
        <App/>
    </CookiesProvider>
    </StrictMode>
    ,
    document.getElementById('root') as HTMLElement
);
// serviceWorkerRegistration.unregister();
