import '../../../style/scss/services.scss';

import { Col, Row } from 'reactstrap';
import Title from '../../common/Title';

import Content from '../../Content';
import InternalLink from '../../common/InternalLink';
import { cmsImageUrl } from '../../../util/api/url';
import ExternalLink from '../../common/ExternalLink';
import MailtoLink from '../../common/MailtoLink';

export default function MetrayaMoveFAQ() {
    const title = 'Matreya is Now a Part of Cayman Chemical';

    return (
        <Content dataStyle={'services'}>
            <Title components={[title]}/>
            <Row>
                <Col lg={12}>
                    <img
                        src={cmsImageUrl('matreya/matreya-moving.png')}
                        className={'img-fluid float-right mb-3 ml-3'}
                        style={{maxWidth: 185}}
                    />

                    <h1>{title}</h1>

                    <p>
                        As of January 1, 2024, Matreya has been fully transitioned over to the Cayman website and brand
                        as part of the{' '}
                        <InternalLink
                            to={'/news/matreya-llc-is-now-a-part-of-cayman-chemical'}
                            className={'text-teal'}
                        >
                            acquisition of Matreya by Cayman Chemical
                        </InternalLink>
                        {'. '}
                        This transition was an essential one to help improve the ordering process, customer service, and
                        overall customer experience. We understand that this may bring up some questions for Matreya
                        customers that we hope are addressed below.
                    </p>

                    <h2>Products and Ordering</h2>

                    <ul>
                        <li className={'mb-2'}>
                            All Matreya products have equivalent Cayman Chemical item numbers and are still searchable on
                            {' '}
                            <InternalLink
                                to={'/'}
                                className={'text-teal'}>

                                www.caymanchem.com
                            </InternalLink>
                            {' '}
                            using the old Matreya item number. You can also
                            {' '}
                            <ExternalLink
                                href={'https://cdn2.caymanchem.com/cdn/cms/caymanchem/cmsImages/matreya/matreya-equivalent-items.pdf'}
                                className={'text-teal'}>

                                download a table of all Matreya products and their Cayman equivalents (PDF)
                            </ExternalLink>
                            {'.'}
                        </li>

                        <li className={'mb-2'}>
                            Manufacturing and quality control of final products will remain unchanged, and customers can expect to receive the same high-quality products they are accustomed to.
                        </li>

                        <li className={'mb-2'}>
                            All orders must be placed at{' '}
                            <InternalLink
                                to={'/'}
                                className={'text-teal'}
                            >
                                www.caymanchem.com
                            </InternalLink>{' '}

                            or by contacting{' '}

                            <MailtoLink
                                email={'sales@caymanchem.com'}
                                className={'text-teal'}
                            >
                                sales@caymanchem.com
                            </MailtoLink>.
                        </li>
                    </ul>

                    <h2>
                        Branding
                    </h2>

                    <ul>
                        <li className={'mb-2'}>
                            All products, sales documents, and quality assurance documents will be branded as Cayman Chemical.
                            This is a process that will take some time, so you may still receive some Matreya-branded products
                            or documents in a Cayman Chemical box, or vice-versa. The quality remains the same.
                        </li>
                    </ul>

                    <h2>
                        Communication
                    </h2>

                    <ul>
                        <li className={'mb-4'}>
                            If you have any questions, please do not hesitate to reach out to your Cayman Sales Representative,
                            Ashley Butler, or call a member of our Customer Service and/or Technical Support departments.
                            All contact information can be found below.
                        </li>
                    </ul>
                </Col>
            </Row>

            <Row>
                <CommunicationLink
                    department={'Sales Representative'}
                    contact={'Ashley Butler'}
                    email={'abutler@matreya.com'}/>

                <CommunicationLink
                    department={'Customer Support'}
                    contact={'(800) 364-9897'}
                    email={'custserv@caymanchem.com'}/>

                <CommunicationLink
                    department={'Technical Support'}
                    contact={'(888) 526-5351'}
                    email={'techserv@caymanchem.com'}/>
            </Row>
        </Content>
    );
}

interface CommunicationLinkProps {
    department: string,
    contact: string,
    email: string,
}

function CommunicationLink(props: CommunicationLinkProps) {
    return (
        <Col lg={4} className={'p-1'}>
            <p className={'bg-teal text-center p-1 m-0'}>
                {props.department}<br/>
                {props.contact}
            </p>

            <MailtoLink
                email={props.email}
                className={'text-lato-bold text-teal text-center p-1 d-block'}>

                {props.email}
            </MailtoLink>
        </Col>
    );
}