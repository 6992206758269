import { observer } from 'mobx-react';
import * as React from 'react';
import { CSSProperties } from 'react';
import { NavLink } from 'react-router-dom';
import { gtag } from '../../constants/gtag';
import { routerStore } from '../../storeSingleton/routerStore';
import { SubscribeLink } from './SubscribeLink';

interface InternalLinkProps {
    className?: string;
    style?: CSSProperties;
    title?: string;
    to: string;
    onClick?: () => void;
    event?: [string, object];
}

const InternalLink = observer(class InternalLink extends React.PureComponent<InternalLinkProps> {
    render() {
        const event = this.props.event;

        const { children, className, style, to} = this.props;

        //  Intercept requests for '/subscribe', return custom-crafted external link for this Mailchimp form
        if ( to === '/subscribe' ) {
            return <SubscribeLink className={className} style={style}>{children}</SubscribeLink>;
        }

        return  <NavLink
            className={`${this.props.className || ''}`}
            style={this.props.style}
            title={this.props.title}
            to={this.props.to}
            onClick={() => {
                if (event) {
                    gtag('event', ...event);
                }

                if (this.props.onClick) {
                    this.props.onClick();
                }
                routerStore.onNavigation();
                return true;
            }}
        >
            {this.props.children}
        </NavLink>;
    }
});
export default InternalLink;