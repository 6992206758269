import '../style/components/Content.scss';

import * as React from 'react';
import { CSSProperties } from 'react';
import { Col, Container, Row } from 'reactstrap';

interface ContentProps {
    id?: string,
    className?: string;

    /*
        defaults to 8
        refers to the number of Bootstrap Grid System columns devoted to content area
        should be an even number between 2 and 10
     */
    size?: number;
    noPadding?: boolean;
    style?: CSSProperties;
    rowClass?: string;
    dataStyle?: string;

    leftImgSrc?: string,
    rightImgSrc?: string,
}

/**
 * Reusable wrapper for all content-oriented pages
 * (About, Management Team, Conference schedule, etc.)
 * That don't span the entire width of a wide viewport
 */
export default class Content extends React.PureComponent<ContentProps> {
    render() {
        const size = this.props.size || 8;
        const offset = (12 - size) / 2;

        const {props} = this;

        let leftImage = null;
        let rightImage = null;

        if (props.leftImgSrc) {
            leftImage = (
                <div className={'banner-img left'}>
                    <img src={props.leftImgSrc}/>
                </div>
            );
        }

        if (props.rightImgSrc) {
            rightImage = (
                <div className={'banner-img right'}>
                    <img src={props.rightImgSrc}/>
                </div>
            );
        }

        return (
            <Container
                id={this.props.id}
                className={`content ${this.props.noPadding ? '' : 'py-3'} ${this.props.className ? this.props.className : ''}`}
                fluid={true}
                style={this.props.style}
                data-style={this.props.dataStyle}>

                {leftImage}

                <Row className={this.props.rowClass}>
                    <Col
                        xs={12}
                        md={12}
                        lg={12}
                        xl={{size: size, order: 2, offset: offset}}>
                        {this.props.children}
                    </Col>
                </Row>

                {rightImage}
            </Container>
        );
    }
}